import * as React from "react";
import { Link } from "gatsby";

import HelmetTemplate from "../components/molecules/HelmetTemplate/HelmetTemplate";

const NotFoundPage = () => {
  return (
    <main>
      <HelmetTemplate title="404" desc="Strona nie została znalezniona" />
      <h1>Strona nie została znalezniona</h1>
      <p>
        Przepraszamy 😔, nie możemy znaleźć tego czego szukasz.
        <br />
        <Link to="/">Strona główna</Link>.
      </p>
    </main>
  );
};

export default NotFoundPage;
